// import Vue from 'vue'
import { orderState, sisasOrderModel } from "@/models/orderModel";
import axios from "axios";
import { Component, Watch } from "vue-property-decorator";
import baseUserContextPage from "../baseUserContextPage";
var Qs = require("qs");

@Component({})
export default class thankyouPage extends baseUserContextPage {
    isLoading: boolean = false;
    order: sisasOrderModel = null;
    get code(): string {
        return this.$route && this.$route.params && this.$route.params.code
            ? this.$route.params.code
            : "none";
    }
    get isERPAlign(): boolean {
        var instance = this;
        return (
            instance.order != null &&
            (instance.order.orderState == orderState.Completed
                || instance.order.orderState == orderState.Cancelled
                || instance.order.orderState == orderState.Processing)
        );
    }
    get orderNumber(): string {
        var instance = this;
        return instance.order != null && instance.order.orderSourceId != null
            ? instance.order.orderSourceId.split("_")[0]
            : null;
    }
    get customerReferenceCode(): string {
        var instance = this;
        return instance.order != null && instance.order.customer_ReferenceCode != null && instance.order.customer_ReferenceCode != ''
            ? instance.order.customer_ReferenceCode
            : null;
    }
    created() {
        var instance = this;

        instance.fetch();
    }
    fetch(): void {
        var instance = this;
        instance.isLoading = true;
        axios
            .get<sisasOrderModel>(`/order/${instance.code}`)
            .then(res => {
                instance.order = res.data ? res.data : new sisasOrderModel();
                instance.isLoading = false;
            })
            .catch(error => {
                console.error(error);
                instance.order = new sisasOrderModel();
                instance.isLoading = false;
            });
    }
}
